import * as React from "react";
import { FunctionComponent } from "react";
import { CheckboxStyle } from "./checkboxStyle";

export interface CheckboxProps {
    value?: boolean;
    onChange?(value: boolean): void;
    className?: string;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ value, onChange, children, className }) => {
    const [checked, setChecked] = React.useState(value || false);

    const handleClick = () => {
        setChecked(!checked);
        onChange(!checked);
    }

    React.useEffect(() => {
        if (value !== checked) {
            setChecked(value);
        }

    }, [value, checked]);

    return (
        <CheckboxStyle className={className}>
            <div onClick={handleClick} className={checked ? "square checked" : "square"}></div>
            {children}
        </CheckboxStyle>
    );
};
