import * as React from "react";
import { FunctionComponent } from "react";
import { GalleryStyle } from "./galleryStyle";
import { Item } from "./item/item";
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import { withPrefix } from "gatsby";

export interface GalleryProps {

}

export const Gallery: FunctionComponent<GalleryProps> = (props: GalleryProps) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const [imageIndex, setImageIndex] = React.useState(0);

    const images = [{},
    {
        img: require("../../images/gallery/1.jpg"),
        thumb: require("../../images/gallery/1-thumb.jpg")
    },
    {
        img: require("../../images/gallery/2.jpg"),
        thumb: require("../../images/gallery/2-thumb.jpg")
    },
    {
        img: require("../../images/gallery/3.jpg"),
        thumb: require("../../images/gallery/3-thumb.jpg")
    },
    {
        img: require("../../images/gallery/4.jpg"),
        thumb: require("../../images/gallery/4-thumb.jpg")
    },
    {
        img: require("../../images/gallery/5.jpg"),
        thumb: require("../../images/gallery/5-thumb.jpg")
    },
    {
        img: require("../../images/gallery/6.jpg"),
        thumb: require("../../images/gallery/6-thumb.jpg")
    },
    {
        img: require("../../images/gallery/7.jpg"),
        thumb: require("../../images/gallery/7-thumb.jpg")
    }

    ]

    const handleOpenImage = (index: number) => {
        if (index === 0) {
            window.open(withPrefix("/hijstabellen.pdf"));
            return;
        }


        setImageIndex(index);
        setIsOpen(true);
    }

    return (
        <GalleryStyle>
            {images.map((img, index) => <Item key={index} onClick={handleOpenImage} thumb={img.thumb} index={index} />)}


            {isOpen && <Lightbox
                mainSrc={images[imageIndex].img}
                nextSrc={images[(imageIndex + 1) % images.length].img}
                prevSrc={images[(imageIndex + images.length - 1) % images.length].img}
                onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
                onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}

                onCloseRequest={() => setIsOpen(false)}
            />}
        </GalleryStyle>
    );
};
