import React from 'react';
import { PageProps } from 'gatsby';


import { IndexStyle } from '../components/indexStyle';
import { Header } from '@/components/header/header';
import { Gallery } from '@/components/gallery/gallery';
import { Helmet } from "react-helmet"
import { ContactForm } from '@/components/contactForm/contactForm';

const Home: React.FC<PageProps> = () => (
    <IndexStyle>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Vandepitte — Hijswerken &amp; Transport</title>
            <meta name="description" content="Vandepitte BVBA, uw partner voor alles van transport en hijswerken!" />

        </Helmet>
        <Header />
        <Gallery />
        <ContactForm />
    </IndexStyle>
);

export default Home;
