import styled from "styled-components";

export const ItemStyle = styled.div`
    transition: all 0.2s;
    cursor: pointer;

    width: 100%;
    img {
        width: 100%;
    }

    &.info {
        background: #FDD617;
        color: #002E65;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        padding: 20px;
        line-height: 34px;

        width: calc(100% - 40px);
    }
`;
