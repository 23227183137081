import styled from "styled-components";
import { Item } from "./item/item";
import { ItemStyle } from "./item/itemStyle";

export const GalleryStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    

    @media (max-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }


    @media (max-width: 700px) {
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }


    @media (max-width: 400px) {
        grid-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }

    &:hover {
        ${ItemStyle}{
            &:hover {
                transform: scale(1.1);
            }

            &:not(:hover){
                opacity: 0.7;
            }
        }
    }

`;
