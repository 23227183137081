import * as React from "react";
import { FunctionComponent } from "react";
import { ContactFormStyle, ErrorMessage, SuccessMessage } from "./contactFormStyle";
import { Checkbox } from "../checkbox/checkbox";
import { Loading } from "../loading/loading";

export interface ContactFormProps {

}

interface FormValues {
    name?: string;
    company?: string;
    email?: string;
    message?: string;
    date?: string;
    privacy?: boolean;
}

interface FormErrors {
    name?: string;
    company?: string;
    email?: string;
    message?: string;
    date?: string;
    privacy?: string;
    submit?: string;
}

export const ContactForm: FunctionComponent<ContactFormProps> = (props: ContactFormProps) => {
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = React.useState<FormValues>({
        name: "",
        company: "",
        date: "",
        email: "",
        message: "",
        privacy: false
    });

    const [errors, setErrors] = React.useState<FormErrors>({});

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setSuccess(false);
        if (validateField(["company", "date", "email", "message", "name", "privacy"], values)) {
            return;
        }

        setLoading(true);

        try {

            const resp = await fetch("/mail.php", { method: "POST", body: JSON.stringify(values) });

            const body = await resp.json();
            if (body.status && body.status === "success") {
                setSuccess(true);
                setValues({
                    name: "",
                    company: "",
                    date: "",
                    email: "",
                    message: "",
                    privacy: false
                });
            }
        } catch (err) {
            setErrors({ submit: "Er ging iets mis tijdens het verzenden, probeer het later opnieuw." });
        }


        setLoading(false);

    }

    const validateField = (fields: (keyof FormValues)[], formValues: FormValues): boolean => {
        const newErrors: FormErrors = { submit: undefined };

        let hasErrors = false;

        const { email, company, date, message, name, privacy } = formValues;

        fields.forEach(field => {
            newErrors[field] = undefined;

            if (field === "email") {
                if (!email) {
                    newErrors.email = "Gelieve een email adres in te vullen";
                    hasErrors = true;
                }

                if (email && !email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    newErrors.email = "Dit is geen geldig emailadres";
                    hasErrors = true;
                }
            }

            if (field === "company" && !company) {
                newErrors.company = "Gelieve een bedrijf in te vullen";
                hasErrors = true;
            }

            if (field === "message" && !message) {
                newErrors.message = "Gelieve een berichtin te vullen";
                hasErrors = true;
            }

            if (field === "name" && !name) {
                newErrors.name = "Gelieve een naam in te vullen";
                hasErrors = true;
            }

            if (field === "privacy" && !privacy) {
                newErrors.privacy = "Gelieve dit veld af te vinken";
                hasErrors = true;
            }
        });

        setErrors({
            ...errors,
            ...newErrors
        });
        return hasErrors;
    }

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof FormValues) => {
        const newValues = {
            ...values,
            [key]: ev.target.value
        };

        setValues(newValues);

        validateField([key], newValues);
    };

    const handleCheckboxChange = (checked: boolean) => {
        const newValues = {
            ...values,
            privacy: checked
        };

        setValues(newValues);
        validateField(["privacy"], newValues);
    }

    return (
        <ContactFormStyle>
            <form noValidate onSubmit={handleSubmit}>
                <label htmlFor="name">Naam  <ErrorMessage>{errors["name"]}</ErrorMessage></label>
                <input className={errors["name"] ? "error" : ""} placeholder="Naam" id="name" type="text" onChange={ev => handleChange(ev, "name")} value={values.name} />

                <label htmlFor="company">Bedrijf <ErrorMessage>{errors["company"]}</ErrorMessage></label>
                <input className={errors["company"] ? "error" : ""} placeholder="Bedrijf" id="company" type="text" onChange={ev => handleChange(ev, "company")} value={values.company} />

                <label htmlFor="email">Email <ErrorMessage>{errors["email"]}</ErrorMessage></label>
                <input className={errors["email"] ? "error" : ""} placeholder="Email" id="email" type="email" onChange={ev => handleChange(ev, "email")} value={values.email} />

                <label htmlFor="message">Bericht (Omschrijf de opdracht/uw vraag zo nauwkeurig mogelijk) <ErrorMessage>{errors["message"]}</ErrorMessage></label>
                <textarea className={errors["message"] ? "error" : ""} rows={8} id="message" onChange={ev => handleChange(ev, "message")} value={values.message} />

                <label htmlFor="date">Datum (indicatie) <ErrorMessage>{errors["date"]}</ErrorMessage></label>
                <input className={errors["date"] ? "error" : ""} placeholder="Email" id="date" type="date" onChange={ev => handleChange(ev, "date")} value={values.date} />

                <ErrorMessage>{errors["privacy"]}</ErrorMessage>
                <div className="form-footer">
                    <div>
                        <Checkbox className={errors["privacy"] ? "error" : ""} value={values.privacy} onChange={handleCheckboxChange}>
                            Ik ga akkoord met&nbsp;<a target="__blank" onClick={e => e.stopPropagation()} href="privacy">het privacybeleid</a>
                        </Checkbox>
                    </div>
                    <div>
                        <ErrorMessage>{errors["submit"]}</ErrorMessage>
                        <button disabled={loading} type="submit">{loading && <Loading />}Verzenden</button>
                    </div>
                </div>
                {success && <SuccessMessage>We hebben je bericht succesvol ontvangen, we nemen zo snel mogelijk contact met je op!</SuccessMessage>}
            </form>
        </ContactFormStyle>
    );
};
