import styled, { keyframes } from "styled-components";

const rotateAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

`

export const LoadingStyle = styled.div`
    animation: ${rotateAnim} 1s linear infinite;
`;
