import styled from "styled-components";

export const CheckboxStyle = styled.div`
    display: flex;
    align-items: center;

    &.error {
        .square {
            background: #BA0232;
        }
    }

    .square {
        background: #0055BA;
        cursor: pointer;
        width: 35px;
        height: 35px;
        margin-right: 15px;
        flex-shrink: 0;

        &.checked {
            background-image: url(${require("../../images/check.svg")});
            background-size: 20px 20px;  
            background-repeat: no-repeat;
            background-position: center;
        }
    }
`;
