import * as React from "react";
import { FunctionComponent } from "react";
import { ItemStyle } from "./itemStyle";

export interface ItemProps {
    index: number;
    thumb?: string;
    onClick(index: number): void
}

export const Item: FunctionComponent<ItemProps> = ({ thumb, index, onClick }) => {
    const handleClick = () => {
        onClick(index);
    }

    return (
        <ItemStyle className={!thumb ? "info" : ""} onClick={handleClick}>
            {thumb ? <img src={thumb} /> : "Download de hijstabellen van de kraan."}
        </ItemStyle>
    );
};
