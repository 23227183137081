import styled from "styled-components";
import { LoadingStyle } from "../loading/loadingStyle";

export const ErrorMessage = styled.span`
    color: #BA0232;
`;

export const SuccessMessage = styled.div`
    color: #6adb23;
    margin-top: 15px;
`;

export const ContactFormStyle = styled.div`
    padding: 40px 0;
    margin: 0 auto ;
    width: 100%;
    max-width: 650px;
 
    .form-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a {
        color: white;
        font-style: italic;
    }

    input, textarea, button {
        width: 100%;
        display: block;
        height: 60px;
        background: #0055BA;
        border: none;
        color: white;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        margin: 0 0 32px 0;
        font-family: inherit;
        transition: all 0.2s;
        -webkit-appearance: none;
        border-radius: 0;

        &.error {
            background: #BA0232;
        
        }

        ::placeholder {
            color: rgba(255,255,255,0.4);
        }
    }

    button {
        margin-bottom: 0;
        background: #FDD617;
        color: #000;
        max-width: 250px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        padding: 0 40px;

        ${LoadingStyle} {
            margin-right: 15px;
        }

        &:hover {
            background: #e3c012;
        }
    }

    textarea {
        height: auto;
    }

    label {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        ${ErrorMessage} {
            text-align: right;
        }
    }


    @media (max-width: 630px) {
        .form-footer {
            flex-direction: column;

            button {
                margin-top: 25px;
                max-width: initial;
                width: 100%;
            }
        }
    }
`;


